import React from "react";

import DefaultLayout from "../../../layouts/default";

const Kontakt = () => (
  <DefaultLayout title="Kontakt">
    <div className="orange portrait">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li className="is-active">
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productText">
              <span className="line">WigaSoft AG</span>
              <span className="line"> – Kontakt</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/portrait/">Übersicht</a>
            </li>
            <li>
              <a href="/portrait/lehrlinge/">Lernende</a>
            </li>
            <li>
              <a href="/portrait/partner/">Partner</a>
            </li>
            <li>
              <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
            </li>
            <li>
              <a className="is-active" href="/portrait/kontakt/">
                Kontakt
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section>
            <div className="cContainer is-color-orange text-centered">
              <h2>Herzlich willkommen!</h2>
              <div className="row has-padding-top">
                <div className="column one-third has-padding-mobile-medium-bottom">
                  <img
                    src="/assets/images/icons/WigaSoft-Kontakt.svg"
                    className="mediumStaticPadding-bottom cIcon"
                  />
                  <h3>WigaSoft AG</h3>
                  <p className="fliesstext">
                    Oberstrasse 222
                    <br />
                    Postfach 51
                    <br />
                    CH-9014 St. Gallen
                  </p>
                  <a
                    href="mailto:info@wigasoft.ch"
                    className="buttonLink is-visible-mobile"
                  >
                    <button className="inverseReactive font-medium orange">
                      E-Mail
                    </button>
                  </a>
                </div>
                <div className="column one-third has-padding-mobile-medium-bottom">
                  <img
                    src="/assets/images/icons/Telefon-Kontakt.svg"
                    className="mediumStaticPadding-bottom cIcon"
                  />
                  <h3>Telefon</h3>
                  <p className="fliesstext">
                    Montag bis Freitag
                    <br />
                    8-12 und 13-17 Uhr
                  </p>
                  <a
                    href="tel:+41712745131"
                    className="buttonLink is-visible-mobile"
                  >
                    <button className="inverseReactive font-medium orange">
                      +41 71-274 51 31
                    </button>
                  </a>
                </div>
                <div className="column one-third has-padding-mobile-medium-bottom">
                  <img
                    src="/assets/images/Hotline-Kontakt.svg"
                    className="mediumStaticPadding-bottom cIcon"
                  />
                  <h3>Hotline</h3>
                  <p className="fliesstext">
                    Montag bis Freitag
                    <br />
                    8-12 und 13-17 Uhr
                  </p>
                  <a
                    href="tel:+41712745133"
                    className="buttonLink is-visible-mobile"
                  >
                    <button className="inverseReactive font-medium orange">
                      +41 71-274 51 33
                    </button>
                  </a>
                  <a
                    href="mailto:hotline@wigasoft.ch"
                    className="buttonLink is-visible-mobile"
                  >
                    <button className="inverseReactive font-medium orange">
                      E-Mail
                    </button>
                  </a>
                </div>
              </div>

              <div className="row is-visible-tablet">
                <div className="column one-third">
                  <a href="mailto:info@wigasoft.ch" className="buttonLink">
                    <button className="inverseReactive font-medium orange">
                      E-Mail
                    </button>
                  </a>
                </div>
                <div className="column one-third">
                  <a href="tel:+41712745131" className="buttonLink">
                    <button className="inverseReactive font-medium orange">
                      +41 71-274 51 31
                    </button>
                  </a>
                </div>
                <div className="column one-third">
                  <a href="tel:+41712745133" className="buttonLink">
                    <button className="inverseReactive font-medium orange">
                      +41 71-274 51 33
                    </button>
                  </a>
                  <a href="mailto:hotline@wigasoft.ch" className="buttonLink">
                    <button className="inverseReactive font-medium orange">
                      E-Mail
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="wrapper">
          <div className="cContainer lessPadding is-background-color-darkGrey is-color-white">
            <div className="anfahrtFlex text-centered">
              <div className="column one-half has-padding-mobile-medium-bottom">
                <div className="row">
                  <div className="column one-third">
                    <img
                      className="anfahrtSVG aAuto"
                      src="/assets/images/Anfahrt-Auto.svg"
                      width="111"
                    />
                  </div>
                  <div className="column two-third">
                    <h3 className="padding-25-bottom">Anfahrt mit Auto</h3>
                    <a
                      target="_blank"
                      href="https://www.google.com/maps/dir//WigaSoft+AG,+Oberstrasse+222,+9014+St.+Gallen/@47.4113781,9.3119105,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x479b1fda103ee90b:0xab4bf02d14aeb323!2m2!1d9.3470156!2d47.4113835!3e0"
                      className="buttonLink"
                    >
                      <button className="newButton hasHigherWidth hoverGrey">
                        zu Google Maps
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="column one-half">
                <div className="row">
                  <div className="column one-third">
                    <img
                      className="anfahrtSVG aOEv"
                      src="/assets/images/Anfahrt-oeV.svg"
                      width="150"
                    />
                  </div>
                  <div className="column two-third">
                    <h3 className="padding-25-bottom">Anfahrt mit öV</h3>
                    <a
                      target="_blank"
                      href="https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?nach=Hechtacker%20St.Gallen"
                      className="buttonLink"
                    >
                      <button className="newButton hasHigherWidth hoverGrey">
                        zum Fahrplan
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/portrait/">Über uns</a>
                </li>
                <li>
                  <a href="/portrait/kontakt/">Kontakt</a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default Kontakt;
